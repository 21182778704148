<template>
  <div class="mt-3">
    <strong>ERROS</strong>
    <TableList :fields="fields.error" :items="list" :ready="ready" />
    <Pagination v-if="showPagination" v-model="pagination" :ready="ready" />
  </div>
</template>

<script>
import paginationMixins from "@/mixin/paginationMixin";
import fields from "../fields";
export default {
  props: ["lote"],
  data() {
    return {
      showPagination: true,
      ready: true,
      fields,
    };
  },
  computed: {
    list() {
      return this.$store.state.sender.sentList.rows;
    },
  },
  methods: {
    get_sent_list(value) {
      let aditionalQuery = {};
      if (value) {
        aditionalQuery = { lote: this.lote, search: value };
        this.showPagination = false;
      } else {
        aditionalQuery = { lote: this.lote };
        this.showPagination = true;
      }

      this.init_pagination({
        url: `/boletos/get_error`,
        vuex: "sender/sentList",
        aditionalQuery,
      });
    },
  },
  mounted() {
    this.$router.push({
      query: { page: 1 },
    });
    this.init_pagination({
      url: `/boletos/get_error`,
      vuex: "sender/sentList",
      aditionalQuery: { lote: this.lote },
    });
  },
  mixins: [paginationMixins],
};
</script>

<style></style>
